import styled from 'styled-components';

export const CardsGrid = styled.div`
  max-width: 1110px;
  margin: 100px auto 0 auto;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 23px;

  @media (max-width: ${(props) => props.theme.breakpoints.lg}px) {
    margin-top: 40px;
    gap: 20px;
  }
`;
