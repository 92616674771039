import React from 'react';
import { graphql } from 'gatsby';

import { ThemeProvider } from '@/themes/theme';
import { GlobalStyle } from '@/themes/globalStyle';
import Wrapper from '@/components/Wrapper';
import { Header } from '@/components/Header';
import { Content } from '@/components/Experts';
import { Footer } from '@/components/Footer';
import { Links } from '@/utils/constants';
import { Meta } from '@/components/general/Meta';

const Experts = ({ pageContext, data }) => (
  <ThemeProvider>
    <Meta
      title="Experts | CodePlatform"
      description="Need some help with your CodePlatform? Browse and connect with CodePlatform Experts below!"
      url={Links.EXPERTS}
      robots={pageContext.tag ? 'noindex' : 'index'}
    />
    <GlobalStyle />
    <Wrapper>
      <Header currentpath={Links.EXPERTS} />
      <Content currentTag={pageContext.tag} data={data} />
      <Footer />
    </Wrapper>
  </ThemeProvider>
);

export default Experts;

export const query = graphql`
  query ExpertsPageQuery {
    contentfulExpertsPage {
      title
      tags
      experts {
        id
        companyName
        role
        agency
        avatar {
          url
        }
        background {
          gatsbyImageData(width: 706, placeholder: BLURRED)
        }
        shortDescription
        reviews {
          rating
        }
      }
    }
  }
`;
