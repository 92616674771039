import React, { useRef, useEffect, useContext } from 'react';

import { Links } from '@/utils/constants';
import {
  TopBoxDescription,
  TagsBox,
  FixedTagsBox,
  LearnMore,
} from '@/components/Templates/Content.styled';
import { removeSpaces, convertToNormal } from '@/utils/functions';
import { Layout, Breadcrumbs, BlurBox } from '@/components/general';
import * as styled from './Content.styled';
import { Tag, TagAttachment, TagVariants } from '../Templates/components';
import { FixedContext } from '../Wrapper';
import { Title, UnderTitle } from '../general/FormPages.styled';
import { Card } from './components/Card';
import { IExpertInfo } from './types';

interface IContent {
  currentTag: string;
  data: any;
}

export const Content: React.FC<IContent> = ({ currentTag, data }) => {
  const expertsPageData = data.contentfulExpertsPage;

  let tagToFilter = convertToNormal(currentTag);
  let filteredExperts: IExpertInfo[];

  if (tagToFilter) {
    const ending = tagToFilter.substring(
      tagToFilter.length - 3,
      tagToFilter.length,
    );
    if (ending === 'ies') {
      tagToFilter = `${tagToFilter.slice(0, -3)}y`;
    } else {
      tagToFilter = tagToFilter.slice(0, -1);
    }
  }

  if (currentTag) {
    filteredExperts = expertsPageData.experts.filter((expert: IExpertInfo) =>
      expert.role.includes(tagToFilter),
    );
  } else {
    filteredExperts = expertsPageData.experts;
  }

  const tagsBox = useRef(null);

  const { isFixed, setIsFixed } = useContext(FixedContext);

  useEffect(() => {
    const addFixed = () => {
      if (window.innerWidth > 1022) {
        if (scrollY > tagsBox?.current?.offsetTop + 45) {
          setIsFixed(true);
        } else {
          setIsFixed(false);
        }
      } else {
        setIsFixed(false);
      }
    };
    addFixed();
    window.addEventListener('scroll', addFixed);
    return () => window.removeEventListener('scroll', addFixed);
  }, [tagsBox, tagsBox.current]);

  return (
    <Layout>
      <Breadcrumbs links={[Links.EXPERTS]} />
      <BlurBox $containTagsBox>
        <TopBoxDescription>
          <Title>CodePlatform Experts</Title>
          <UnderTitle>
            CodePlatform certified partners, studios and independent consultants
            that can breathe life into your dream app.
          </UnderTitle>
          <LearnMore to={Links.PARTNERS_PROGRAM}>
            Learn more about Partners program
          </LearnMore>
        </TopBoxDescription>
        <div id="tags-box">
          <TagsBox ref={tagsBox}>
            <Tag
              $active={!currentTag}
              variant={TagVariants.BIG}
              attachment={TagAttachment.EXPERTS}
              allTag
            >
              All Experts
            </Tag>
            {expertsPageData.tags.map((tag: string) => (
              <Tag
                key={tag}
                $active={removeSpaces(tag) === currentTag}
                variant={TagVariants.BIG}
                attachment={TagAttachment.EXPERTS}
              >
                {tag}
              </Tag>
            ))}
          </TagsBox>
          <FixedTagsBox $fixed={isFixed} $hideResolution={1023}>
            <Tag
              $active={!currentTag}
              $fixedTagBox
              fixedTime={0.25}
              $fixed={isFixed}
              variant={TagVariants.BIG}
              attachment={TagAttachment.EXPERTS}
              allTag
            >
              All Experts
            </Tag>
            {expertsPageData.tags.map((tag: string, index: number) => (
              <Tag
                key={tag}
                $active={removeSpaces(tag) === currentTag}
                $fixedTagBox
                fixedTime={(index + 2) / 4}
                $fixed={isFixed}
                variant={TagVariants.BIG}
                attachment={TagAttachment.EXPERTS}
                currentTag={currentTag}
              >
                {tag}
              </Tag>
            ))}
          </FixedTagsBox>
        </div>
        <styled.CardsGrid>
          {filteredExperts.map((expert: IExpertInfo, index) => (
            <Card
              card={expert}
              key={expert.companyName}
              externalIndex={index}
            />
          ))}
        </styled.CardsGrid>
      </BlurBox>
    </Layout>
  );
};
